var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"employee"},[_c('Header'),_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row mt-2 height-sb"},[_c('div',{staticClass:"col-sm-2"},[_c('SidebarReports')],1),_c('div',{staticClass:"col-sm-2"},[_vm._m(1),_vm._m(2),_c('b-form-datepicker',{attrs:{"required":""},on:{"input":_vm.reportdatefrom},model:{value:(_vm.selected_report_date_from),callback:function ($$v) {_vm.selected_report_date_from=$$v},expression:"selected_report_date_from"}}),_vm._m(3),_c('b-form-datepicker',{attrs:{"required":""},on:{"input":_vm.reportdateto},model:{value:(_vm.selected_report_date_to),callback:function ($$v) {_vm.selected_report_date_to=$$v},expression:"selected_report_date_to"}}),_vm._m(4),_c('b-form-select',{attrs:{"options":_vm.options_branch},model:{value:(_vm.selected_branch),callback:function ($$v) {_vm.selected_branch=$$v},expression:"selected_branch"}}),_c('hr'),_vm._m(5)],1),_c('div',{staticClass:"col-sm-8"},[_c('center',[_c('h6',[_vm._v("AL Busaili")]),_c('h6',[_vm._v("RIYADH")]),_c('h5',[_vm._v("Account Balances [Tree View]")]),_c('h6',[_vm._v("As at: 11/12/2021")]),_c('h6',[_vm._v("Branch : All")])]),(_vm.selected_items.length > 0)?_c('b-pagination',{staticClass:"customPagination",attrs:{"total-rows":_vm.rows,"per-page":15,"pills":"","size":"sm","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.selected_items,"per-page":15,"current-page":_vm.currentPage}})],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"heading-title"},[_vm._v(" Reports ")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3 mb-2"},[_c('b',[_vm._v("General ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3 mb-2"},[_c('b',[_vm._v(" Date From")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3 mb-2"},[_c('b',[_vm._v(" Date To")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3 mb-2"},[_c('b',[_vm._v("Branch ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-info"},[_vm._v("Save")])])
}]

export { render, staticRenderFns }